import React from 'react'
import Styles from './ActivityMessage.module.css'
import { formatDistanceToNow, parseISO } from 'date-fns';
import { Avatar, Flex } from '@chakra-ui/react';
export default function ActivityMessageComponent({ message }) {
    // Handle dates
    const createdDate = message.createdDate ? parseISO(message.createdDate) : new Date();
    const createdAgo = formatDistanceToNow(createdDate, { addSuffix: true });
    return (
        <div>
            <Flex alignItems={'center'} w={'100%'} gap={3}>
                <Avatar
                    mt={"2.5px"}
                    border={'1px solid #74879c6c'}
                    boxSize='33px'
                    size={'s'}
                    fontWeight={'600'}
                    name={message.email}
                    cursor="pointer"
                />
                <Flex flexDir={'column'} w={'100%'}>
                    <Flex flexDir={'column'} alignItems={'flex-start'}>
                        <p className={Styles.UserName}>{message?.email?.replace(/@.+$/, "")}</p>
                        <p className={Styles.details}>{message.action.replace(/([a-zA-Z0-9._%+-]+)@gmail\.com/g, "$1")}</p>
                        <p className={Styles.details}>{createdAgo}</p>
                    </Flex>
                </Flex>
            </Flex>
        </div>
    )
}
