import React, { useEffect, useState } from 'react';
import Styles from './WorkspaceSettings.module.css';
import AboutWorkspace from '../AboutWorkspace/AboutWorkspace';
import InviteModal from '../InviteModal/InviteModal';
import Dvider from '../Dvider';
import Button from "react-bootstrap/Button";
import { Flex } from '@chakra-ui/react';
import { DeleteWorkSpace, UploadImage, DeleteImage } from '../../Service/WorkSpaceService'; // Import the DeleteImage function
import { useMutation, useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import CustomModal from '../CustomModal/CustomModal';
import { setData } from '../../Redux/Slices/WorkspaceAndBorderSlice';
import { toast, ToastContainer } from 'react-toastify';

export default function WorkspaceSettings({ isSuccess, workspaceData, id, toggleInviteModal, IsWorkspaceInviteModalOpen }) {
    const queryClient = useQueryClient();
    const [modalShow, setModalShow] = useState(false);
    const [answer, setAnswer] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // New state for selected image
    const { userId } = useSelector((x) => x.userCredentials);
    const { workspaceId } = useSelector((state) => state.workspaceAndBoard);
    const dispatch = useDispatch();

    const { mutate: DeleteWorks } = useMutation(
        () => DeleteWorkSpace(userId, workspaceId),
        {
            onSuccess: () => {
                setShowAlert(true);
                queryClient.invalidateQueries("GetWorkspaceById");
                queryClient.invalidateQueries("GetAllworkspaces");
                dispatch(setData({ workspaceId: '' }));
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            },
            onError: (err) => {
                console.error(err);
            },
        }
    );

    const { mutate: RemoveImage } = useMutation(
        () => DeleteImage(workspaceId, userId),
        {
            onSuccess: () => {
                toast.success('Image removed successfully!');
                setSelectedImage(null); // Clear selected image
                queryClient.invalidateQueries("GetWorkspaceById");
                queryClient.invalidateQueries("GetAllworkspaces");

            },
            onError: (err) => {
                toast.error('Failed to remove image. Please try again.');
                console.error(err);
            }
        }
    );

    useEffect(() => {
        if (answer) {
            DeleteWorks(userId, workspaceId);
            setAnswer(false);
        }
    }, [answer]);

    const updateParentState = (modalShow, Answer) => {
        setModalShow(modalShow);
        setAnswer(Answer);
    };


    const handleFileUpload = async (file) => {
        try {
            const response = await UploadImage(workspaceId, userId, file); // Call the upload function
            if ("Upload successfully.") {
                toast.success('Image uploaded successfully!');
                queryClient.invalidateQueries("GetAllworkspaces");

            } else {
                toast.error('Failed to upload image. Please try again.');
            }

            queryClient.invalidateQueries("GetWorkspaceById");
        } catch (error) {
            toast.error('Error uploading image. Please try again.');
        }
    };

    const handleRemoveImage = () => {
        RemoveImage();
    };
    const handleInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1 * 1024 * 1024) { // Check if file size exceeds 1 MB
                toast.error('File size exceeds 1 MB. Please select a smaller image.');
                return;
            }
            setSelectedImage(file); // Set the selected image
            handleFileUpload(file); // Automatically handle file upload
        }
    };
    return (
        <>
            <ToastContainer />
            <div className={Styles.MainContainer}>
                <InviteModal SetAddUserLoading={console.log()} toggleInviteModal={toggleInviteModal} IsWorkspaceInviteModalOpen={IsWorkspaceInviteModalOpen} id={id} />
                <AboutWorkspace openInviteModal={toggleInviteModal} workspaceData={workspaceData} />
                <div className={Styles.SettingsContainer}>
                    <p className={Styles.SettingsHeader}>
                        Workspace settings
                    </p>
                    <p style={{ padding: "20px 0 10px  0", margin: "0", fontWeight: 500, fontSize: "19px" }}>Delete Workspace</p>
                    <Dvider color="rgb(159 173 188 / 24%)" m={"2px 0"} />
                    <Flex className={Styles.DeleteSection}>
                        <p> Deleting a workspace is a serious action and can only be performed by an Admin. </p>
                        <Button
                            style={{ maxHeight: "" }}
                            type="submit"
                            className={Styles.ButtonDelete}
                            onClick={() => updateParentState(true, false)}
                        >
                            Delete
                        </Button>
                    </Flex>
                </div>
                <div className={Styles.SettingsContainer}>
                    <p style={{ padding: "20px 0 10px  0", margin: "0", fontWeight: 500, fontSize: "19px" }}>Upload a Custom Image for Your Workspace</p>
                    <Dvider color="rgb(159 173 188 / 24%)" m={"2px 0"} />
                    <Flex className={Styles.DeleteSection}>
                        <p> Personalize your workspace with a custom image. </p>
                        <Flex className={Styles.ButtonsContainer}>
                            <input
                                type="file"
                                accept="image/*"
                                id="upload-image"
                                className={Styles.UploadImageButton}
                                onChange={handleInputChange}
                            />
                            {workspaceData?.data?.imageBinaryData && (
                                <Button
                                    style={{ marginTop: "10px" }}
                                    type="button"
                                    className={Styles.Button}
                                    onClick={handleRemoveImage}
                                >
                                    Remove Image
                                </Button>
                            )}
                        </Flex>
                    </Flex>
                </div>
            </div>
            <CustomModal
                type={'delete'}
                object={'workspace'}
                message={`Are you sure you want to delete this workspace?`}
                title={'Delete Workspace'}
                show={modalShow}
                updateParentState={updateParentState}
            />
        </>
    );
}
