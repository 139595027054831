import { httpClient } from "../Utils/HttpClient";
import { useQueryClient } from "react-query";

export const CreateChecklist = async (formData) => {
    try {
        const response = await httpClient.post('/api/Checklists', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const CreateChecklistitem = async (formData) => {
    try {
        const response = await httpClient.post('/api/Checkitems', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

// export const UpdateChecklistItem = async (formData) => {
//     try {
//         const response = await httpClient.put('/api/CardLists/update', formData, {
//             headers: {
//                 'Content-Type': 'multipart/form-data'
//             }
//         });
//         return response.data;
//     } catch (error) {
//         throw error;
//     }
// };

export const RemoveCardList = ({ CardlistId, userId, workspaceId }) => {
    return httpClient.delete(`/api/CardLists/remove?CardlistId=${CardlistId}&WorkspaceId=${workspaceId}&UserId=${userId}`, {
    });
};

export const GetAllChecklist = async (Id) => {
    try {
        const response = await httpClient.get(`/api/Checklists?CardId=${Id}`)
        return response.data;
    } catch (error) {
    }
};
export const DeleteChecklistItem = async (Id) => {
    try {
        const response = await httpClient.delete(`/api/Checkitems?CheckItemId=${Id}`)
        return response.data;
    } catch (error) {
    }
};
export const DeleteChecklist = async (Id, UserId, WorkspaceId) => {
    try {
        const response = await httpClient.delete(`/api/Checklists?CheckListId=${Id}&WorkspaceId=${WorkspaceId}&UserId=${UserId}`)
        return response.data;
    } catch (error) {
    }
};
export const CheckItemUpdate = async (Id, data) => {
    try {
        const response = await httpClient.put(`/api/Checkitems/${Id}`, data);
        return response.data;
    } catch (error) {
        console.error('Failed to update checklist item:', error);
        throw error;
    }
};

export const UpdateChecklistItem = (data) => {
    httpClient.put(`/api/Checkitems/ChangeChecklistItemTitle`, data);
};
export const UpdateChecklist = (data) => {
    httpClient.put(`/api/Checklists/ChangeChecklistTitle`, data);
};
export const HideChecklist = async (queryClient, CheckItemId, UserId, WorkspaceId, IsHiddenValue) => {
    try {
        const response = await httpClient.put(`/api/Checkitems/ChangeHiddedStatus`, null, {
            params: {
                CheckItemId,
                WorkspaceId,
                UserId,
                IsHidden: IsHiddenValue,
            }
        });

        // Invalidate the query to trigger refetch
        queryClient.invalidateQueries("getAllCheklist");
        queryClient.invalidateQueries("boardData");
        
        return response.data;
    } catch (error) {
        console.error('Failed to update checklist item:', error);
        throw error;
    }
};

export const GetHiddenChecklist = async ({ checkListId, userId, workspaceId }) => {
    try {
        const response = await httpClient.get('/api/Checkitems/GetHiddenCheckItems', {
            params: {
                CheckListId: checkListId,  // Use the correct parameter names as per your API
                UserId: userId,
                WorkspaceId: workspaceId,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Failed to fetch hidden checklist items:', error);
        throw error;
    }
};
export const UpdateChecklistTitle = async (data) => {
    try {
        const response = await httpClient.put('/api/CardLists/update', JSON.stringify(data), {
            headers: {
                'Content-Type': 'application/json', // Ensure the content type is JSON
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
