import React, { useState, useEffect } from 'react';
import { Flex, Avatar, Button, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, PopoverArrow, PopoverCloseButton, useDisclosure, Textarea, Input } from '@chakra-ui/react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import Styles from './Comment.module.css';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { DeleteComment, EditComment, CreateComment } from '../../Service/CommnetsService';

export default function Comment({ comment }) {
    const { userId, email } = useSelector((x) => x.userCredentials);
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();

    // State for managing edit mode and comment text
    const [isEditing, setIsEditing] = useState(false);
    const [commentText, setCommentText] = useState(comment.message);

    // Handle dates
    const createdDate = comment.createdDate ? parseISO(comment.createdDate) : new Date();
    const modifiedDate = comment.modiffiedDate ? parseISO(comment.modiffiedDate) : new Date();
    const createdAgo = formatDistanceToNow(createdDate, { addSuffix: true });
    const modifiedAgo = formatDistanceToNow(modifiedDate, { addSuffix: true });
    const agoText = createdDate.getTime() === modifiedDate.getTime() ? `${createdAgo}` : `${modifiedAgo} (edited)`;

    // Delete comment mutation
    const deleteMutation = useMutation({
        mutationFn: (commentId) => DeleteComment(userId, commentId),
        onSuccess: () => {
            queryClient.invalidateQueries(['Comments', comment.cardId]);
            onClose();
        },
        onError: () => {
            console.error('Failed to delete comment');
        }
    });

    // Edit comment mutation
    const editMutation = useMutation({
        mutationFn: (updatedComment) => EditComment(updatedComment),
        onSuccess: () => {
            queryClient.invalidateQueries(['Comments', comment.cardId]);
            setIsEditing(false); // Exit edit mode
        },
        onError: () => {
            console.error('Failed to edit comment');
        }
    });

    // Handle delete
    const handleDelete = () => {
        deleteMutation.mutate(comment.id);
    };

    // Handle edit submit
    const handleEdit = async () => {
        if (commentText.trim() === '') {
            return; // Prevent submitting empty comments
        }

        try {
            await editMutation.mutateAsync({
                ...comment,
                message: commentText,
            });
        } catch (error) {
            console.error('Error editing comment:', error);
        }
    };
    useEffect(() => {
        setCommentText(comment.message)
    }, [comment.message, isEditing])
    return (
        <Flex alignItems={'flex-start'} w={'100%'} gap={3}>
            <Avatar
                mt={"2.5px"}
                border={'1px solid #74879c6c'}
                boxSize='33px'
                size={'s'}
                fontWeight={'600'}
                name={comment.email}
                cursor="pointer"
            />
            <Flex flexDir={'column'} w={'100%'}>
                <Flex alignItems={'center'} gap={2}>
                    <p className={Styles.UserName}>{comment?.email?.replace(/@.+$/, "")}</p>
                    <p className={Styles.details}>{agoText}</p>
                </Flex>
                <Flex gap={2} flexDir={'column'} w={'100%'}>
                    {isEditing ? (
                        <Flex flexDir={'column'}>
                            <input
                                value={commentText}
                                defaultValue={commentText}
                                className={Styles.CommentSection}
                                onChange={(e) => setCommentText(e.target.value)}
                                placeholder="Edit your comment..."
                                size="sm"
                                mb={2}
                            />
                            <Flex p={"10px 0"} align={'center'} gap={2}>

                                <Button
                                    onClick={handleEdit}
                                    isLoading={editMutation.isLoading}
                                    className={Styles.ButtonSave}
                                    _hover={{bg:'transparent'}}
                                >
                                    Save
                                </Button>
                                <Button
                                    className={Styles.Button}
                                    onClick={() => setIsEditing(false)}
                                    ml={2}
                                    _hover={{bg:'transparent'}}

                                >
                                    Cancel
                                </Button>
                            </Flex>
                        </Flex>
                    ) : (
                        <p className={Styles.CommentSection}>{comment.message}</p>
                    )}
                </Flex>
                {userId === comment.appUserId && (
                    <Flex alignItems={'center'} gap={1}>
                        {!isEditing && (
                            <>
                                <p className={Styles.Buttons} onClick={() => setIsEditing(true)}>Edit</p>
                                <p className={Styles.dot}>•</p>
                                <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} zIndex={2}>
                                    <PopoverTrigger>
                                        <p className={Styles.Buttons}>Delete</p>
                                    </PopoverTrigger>
                                    <PopoverContent borderColor={'#808d9766'} bgColor={"#384148 !important"} color="#d4d6d8">
                                        <PopoverArrow bg={'#384148'} />
                                        <PopoverHeader borderColor={'#808d9766'}>Are you sure you want to remove?</PopoverHeader>
                                        <PopoverCloseButton />
                                        <PopoverBody>
                                            <Button
                                                style={{ width: 'max-content', backgroundColor: "transparent", color: "#d4d6d8", padding: "3px 11px" }}
                                                className={'Button'}
                                                onClick={handleDelete}
                                                isLoading={deleteMutation.isLoading}
                                            >
                                                {deleteMutation.isLoading ? 'Removing...' : 'Remove'}
                                            </Button>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </>
                        )}
                    </Flex>
                )}
            </Flex>
        </Flex >
    );
}
