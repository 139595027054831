import { httpClient } from "../Utils/HttpClient";

export const getCardInCustomFields = (cardId) => {
  return httpClient.get(`api/CustomFields?CardId=${cardId}`);
};
export const GetCustomFiledsToShowOnCard = (cardId) => {
  return httpClient.get(`/api/CustomFields/GetSelectedCustomFields?cardId=${cardId}`);
};
export const UpdateCustomNumber = (Id,value,UserId,WorkspaceId,cardId) => {
  return httpClient.put(`/api/CustomFields/UpdateNumberField?value=${value}&Id=${Id}&UserId=${UserId}&WorkspaceId=${WorkspaceId}&CardId=${cardId}`);
};
export const ToggleShowOnCard = (CustomFieldId,FieldType) => {
  return httpClient.put(`/api/CustomFields/ToggleCustomFieldOnCard?customFieldId=${CustomFieldId}&customFieldType=${FieldType}`);
};
export const CreateDropdown = (value) => {
  return httpClient.post(`/api/CustomFields/CreateDropdown`,value);
};
export const RemoveDropDown = (value) => {
  return httpClient.post(`/api/CustomFields/RemoveDropDown`,value);
};
export const SetOption = (DropdownId,OptionId,UserId,WorkspaceId,CardId) => {
  return httpClient.post(`/api/CustomFields/SetOptionToDropdown?dropDownId=${DropdownId}&dropdownOptionId=${OptionId}&UserId=${UserId}&WorkspaceId=${WorkspaceId}&CardId=${CardId}`);
};