import React, { useState } from 'react'
import Styles from './BoardsPage.module.scss'
import SideBarMenu from '../../Components/SideBarMenu/SideBarMenu'
import Content from './BoardsPageComponents/Content'
import { useLocation, useParams } from 'react-router'

export default function BoardsPage({image}) {

  return (
    <>
      <div
        style={{
          backgroundImage: `url(/Images/${image}.jpg)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: "94vh",
          overflow: "hidden",
          width:"100%"
        }}
        className={Styles.MainContainer}>
        <Content />
      </div>
    </>
  )
}
