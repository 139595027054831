import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import Styles from '../../../Components/SideBarMenu/SideBarMenu.module.css';
import CardList from '../CardList/CardList';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getBoardById, getBoardUsers, RemoveUserFromBoard } from '../../../Service/BoardService';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router';
import { ArchiveCard, GetArchivedCards, RemoveCard, RemoveUserFromCard } from '../../../Service/CardService';
import { Menu, MenuButton, MenuList, MenuItem, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, ChakraProvider, Alert, AlertIcon, FormLabel, Input, FormControl, Flex, Avatar, PopoverArrow } from '@chakra-ui/react'
import { toast } from 'react-toastify';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverCloseButton
} from '@chakra-ui/react';
import Dvider from '../../../Components/Dvider';
import { PopoverBody } from 'react-bootstrap';
export default function Content() {
    const [filterData, setFilterData] = useState();
    const { workspaceId, BoardId } = useSelector((x) => x.workspaceAndBoard)

    const { userId } = useSelector((x) => x.userCredentials);
    const queryClient = useQueryClient()
    const { data: Data, isLoading, error, isSuccess } = useQuery(
        ["boardData", BoardId],
        () => getBoardById(BoardId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );
    const { data: archivedCards } = useQuery(
        ["GetArhivedCards", BoardId],
        () => GetArchivedCards(BoardId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: 1000
        }
    );
    const [boardData, setBoardData] = useState()
    useEffect(() => {
        if (Data && isSuccess) {
            setBoardData(Data);
        }
    }, [Data, isSuccess]);
    const formik = useFormik({
        initialValues: {
            title: '',
            option: '',
        },
        onSubmit: (values) => {
            setFilterData(values);
        },
    });
    const navigate = useNavigate()

    //arhive
    //Archive Data
    const ArchiveFormik = useFormik({
        initialValues: {
            isArchived: false,
            cardId: '',
            adminId: userId,
            workspaceId: workspaceId
        },
        onSubmit: async (values) => {
            ArchiveMutation(values);
        },
    });

    const { mutate: ArchiveMutation } = useMutation(
        (data) => ArchiveCard(data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("boardData");
                queryClient.invalidateQueries("GetArhivedCards");

            },
            onError: (err) => {
                toast.error(`Access denied !`);
            },
        }
    );
    //Delete card 
    //remove card Formik 
    const confirmDelete = (id) => {
        RemoveCardMutation(id);
    };
    const { mutate: RemoveCardMutation } = useMutation(
        (cardId) => RemoveCard(cardId, userId, workspaceId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("GetArhivedCards");
                queryClient.invalidateQueries("boardData");
                queryClient.invalidateQueries(['Activity']);
            },
            onError: (err) => {
                toast.error("No Access!")
            },
        }
    );
    const { data: BoardUsersData } = useQuery(
        ["BoardUsers", BoardId],
        () => getBoardUsers(BoardId),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            cacheTime: 1000
        }
    );
    //remove User From Board 
    const RemoveUserToWorkspaceFormik = useFormik({
        initialValues: {
            memberId: "",
            cardId: '',
            adminId: userId,
            workspaceId: workspaceId,
            BoardId: BoardId
        },
        onSubmit: async (values) => {
            await RemoveUserFromBoardMutation(values);
        },
    });

    const {  onClose } = useDisclosure();
    const { mutate: RemoveUserFromBoardMutation } = useMutation(
        async (values) => RemoveUserFromBoard(values),
        {
            onSuccess: async (response) => {
                queryClient.invalidateQueries(["boardData"]);
                queryClient.invalidateQueries(['BoardUsers', BoardId]);

                onClose()
            },
            onError: (err) => {
                onClose()
            },
        }
    );
    return (
        <div className={Styles.MainContainer} style={{ overflow: 'hidden' }}>
            <Col lg={12} className={Styles.sideBarMenuTopMenuWrapper}>
                <Container fluid className={[Styles.sideBarMenuTopMenu, "flex-wrap flex-column flex-md-row"]}>
                    <div className='d-flex align-items-center col-8 col-md-6 justify-content-start'>
                        <h5 id='boardName' className={Styles.boardName}>{boardData?.data?.title}</h5>
                    </div>

                    {/* <DropdownButton className={Styles.workspacePrivacyDropdown} title="Filters">
                            <div className={Styles.ContentDropdown}>
                            <form onSubmit={formik.handleSubmit}>
                            <Flex alignItems={'center'} justifyContent={"center"}>
                                        <h1 className={Styles.FilterMenuHeader}>
                                            Filter
                                        </h1>
                                    </Flex>
                                    <Flex flexDir={'column'}>
                                        <h1 className={Styles.FilterMenuHeader}>Keyword</h1>
                                        <input
                                            className={Styles.FilterInput}
                                            type="text"
                                            value={formik.values.title}
                                            onChange={formik.handleChange}
                                            name='title'
                                        />
                                        <p className={Styles.FilterDesc}>Search cards, members, labels, and more.</p>
                                    </Flex>
                                </form>
                            </div>
                        </DropdownButton> */}
                    <div className={Styles.workspacePrivacyDropdownWrapper}>
                        <div>
                            <ChakraProvider>
                                {BoardUsersData?.data?.length > 0 && (<>
                                    <Flex padding={"0 10px "} maxW={'100%'} flexWrap={'wrap'} gap={1}>
                                        {BoardUsersData?.data?.map((member, index) => (
                                            <Popover
                                                closeOnBlur={true}
                                                key={index}
                                                onClose={onClose}  // Handle popover close event
                                                >
                                                <PopoverTrigger>
                                                    <Avatar  style={{ width: "35px", height: "34px" }} border={'1px solid #74879c6c'} boxSize='33px' size={'s'} fontWeight={'600'} name={member.email} cursor="pointer" />
                                                </PopoverTrigger>
                                                <PopoverContent className={Styles.PopoverMembers}>
                                                    <PopoverCloseButton />
                                                    <PopoverBody paddingTop={4}>
                                                        <p style={{ margin: "0", fontSize: "16px" }}>
                                                            {member?.email?.replace(/@.+$/, "")}
                                                        </p>
                                                        <Dvider color="rgb(159 173 188 / 24%)" m={"10px 0"} />
                                                        <button
                                                            onClick={() => {
                                                                RemoveUserToWorkspaceFormik.setFieldValue("memberId", member.id);
                                                                RemoveUserToWorkspaceFormik.setFieldValue("cardId", BoardId);
                                                                RemoveUserToWorkspaceFormik.handleSubmit()
                                                            }}>Remove from card</button>
                                                    </PopoverBody>
                                                </PopoverContent>
                                            </Popover>
                                        ))}
                                    </Flex>
                                </>
                                )}
                            </ChakraProvider>

                        </div>
                        <DropdownButton className={Styles.workspacePrivacyDropdown} title="Menu">
                            <div className={Styles.ContentDropdown}>
                                <form onSubmit={formik.handleSubmit}>
                                    <Flex alignItems={'center'} justifyContent={"center"}>
                                        <h1 className={Styles.FilterMenuHeader}>
                                            Archived Cards
                                        </h1>
                                    </Flex>
                                    <Flex flexDir={'column'} gap={5} p={'15px 0'}>
                                        {archivedCards?.data?.length > 0 ? (
                                            archivedCards?.data?.map((card, index) => (
                                                <>
                                                    <div key={index} className={Styles.ArchivedCardItem}>
                                                        <Flex align={'center'} justifyContent={'space-between'}>
                                                            <div>
                                                                <p className={Styles.ArchivedCardtitle}>Card name : <b>{card.title}</b></p>
                                                                <Flex gap={5} align={'center'}>
                                                                    <span style={{ fontSize: "17px" }} className="material-symbols-outlined">
                                                                        inventory_2
                                                                    </span>
                                                                    <p className={Styles.ArchivedText}>Archived</p>
                                                                </Flex>
                                                            </div>
                                                            <Menu>
                                                                <MenuButton bgColor={'transparent'}>
                                                                    <FontAwesomeIcon icon={faEllipsis} />
                                                                </MenuButton>
                                                                <MenuList w={"200px"} border={"#616466 1px solid"} borderRadius={4} pb={2} pt={2} gap={10} bgColor={'#1d2125'}>
                                                                    <MenuItem type='submit' onClick={() => { ArchiveFormik.setFieldValue("cardId", card.id); ArchiveFormik.handleSubmit() }} backgroundColor={"transparent"} _hover={{ backgroundColor: "#616466" }} p={"0px 12px"}>Unarchive card</MenuItem>
                                                                    <MenuItem type='submit' onClick={() => { confirmDelete(card.id) }} backgroundColor={"transparent"} _hover={{ backgroundColor: "#616466" }} p={"0px 12px"}>Remove card</MenuItem>
                                                                </MenuList>
                                                            </Menu>
                                                        </Flex>
                                                    </div>
                                                </>
                                            ))
                                        ) : (
                                            <div>No archived cards found.</div>
                                        )}
                                    </Flex>
                                </form>
                            </div>
                        </DropdownButton>
                    </div>
                </Container>
            </Col>
            <Col lg={12} >
                <CardList boardData={boardData?.data} />
            </Col>
        </div>
    )
}
