import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Styles from "./Members.module.css";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import {
  ChangeUserRoleInWorkspace, DeleteUserFromWorkspace,
  GetAllUsersOfWorkspace,
  GetAllWorkspaces, getAllUsersCount
} from "../../Service/WorkSpaceService";
import { useFormik } from "formik";
import {
  CircularProgress,
  ChakraProvider,
  Flex,
  Select,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  useDisclosure,
  Portal
} from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody, PopoverArrow,
  PopoverCloseButton
} from '@chakra-ui/react';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useQueryClient } from "react-query";
import Dvider from "../Dvider";
import { ModalBody, ModalFooter, ModalHeader, Pagination, Spinner } from "react-bootstrap";
import * as Yup from 'yup';
import { httpClient } from "../../Utils/HttpClient";
import AboutWorkspace from "../AboutWorkspace/AboutWorkspace";
import InviteModal from "../InviteModal/InviteModal";

export default function Members({ isSuccess, workspaceData, id, toggleInviteModal, IsWorkspaceInviteModalOpen }) {

  const { userId } = useSelector((x) => x.userCredentials)
  const [adminCount, setTotalAdminCount] = useState(0);
  const [MemberCount, setTotalMemberCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const { isOpen, onClose } = useDisclosure()
  const [inputResult, setInputResult] = useState(true);
  const [Workspaces, setWorkspaces] = useState();
  const [AddUserLoading, SetAddUserLoading] = useState()

  const queryClient = useQueryClient();
  const { mutate: GetUsersAllWorkSpaces } = useMutation(
    (userId) => GetAllWorkspaces(userId),
    {
      onSuccess: (values) => {
        setWorkspaces(values?.data);
      },
      onError: () => { },
    }
  );
  const hasSetCounts = useRef(false);
  const { isLoading: CountUsersLoading, data } = useQuery(
    ["UsersCount", id],
    () => getAllUsersCount(id),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes 
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!hasSetCounts.current) {
          // Save data to localStorage
          localStorage.setItem("adminCount", data?.data?.adminCount);
          localStorage.setItem("memberCount", data?.data?.memberCount);
          hasSetCounts.current = true;
        }
        console.log(data);
      },
      onError: (error) => {
        console.error('Error fetching workspace data:', error);
      },
    }
  );
  useEffect(() => {
    const savedAdminCount = localStorage.getItem("adminCount");
    const savedMemberCount = localStorage.getItem("memberCount");

    if (savedAdminCount) {
      setTotalAdminCount(parseInt(savedAdminCount, 10));
    }
    if (savedMemberCount) {
      setTotalMemberCount(parseInt(savedMemberCount, 10));
    }
  }, []);



  useEffect(() => {
    GetUsersAllWorkSpaces(userId);
  }, [userId]);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await httpClient.get(
          `/api/AppUser/SearchUserByEmailorUsername?value=${searchValue}`
        );
        setSearchResult(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const timerId = setTimeout(() => {
      if (searchValue.trim() !== "") {
        fetchData();
      } else {
        setSearchResult(null);
      }
    }, 500);
    return () => clearTimeout(timerId);
  }, [searchValue]);

  // Directly use useQuery at the top level of your component
  useEffect(() => {
  }, [isSuccess, workspaceData]);
  const [page, setPage] = useState(1);
  const { data: Users, isLoading: isLoadingUsers } = useQuery(
    ["getAllusersOfWorkspce", id, page, 4],
    () => GetAllUsersOfWorkspace(id, page, 4),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 10, // 10 minutes 
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
    {
      onSuccess: (data) => {
        const admins = data?.data?.filter(user => user.role === 'Admin').length;
        setTotalAdminCount(admins );
      },
      onError: (error) => {
        console.error('Error fetching workspace data:', error);
      },
    }
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredUsers(Users?.data || []);
    } else {
      const filtered = Users?.data?.filter(user =>
        user.email?.replace(/@.+$/, "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.role.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  }, [searchTerm, Users]);

  //Change user Role mutation 
  const UpdateUserRoleFormik = useFormik({
    initialValues: {
      adminId: userId,
      userId: '',
      role: "Member",
      WorkspaceId: id,
    },
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      UpdateUserRoleMutation(values)
    },
    onError: () => {
      toast.error("no Access!");
    }, onSuccess: () => {

      queryClient.invalidateQueries("getAllusersOfWorkspce");

    },
  });
  const { mutate: UpdateUserRoleMutation, isLoading: RoleChangeLoading } =
    useMutation((values) => ChangeUserRoleInWorkspace(values), {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllusersOfWorkspce");
      },
      onError: () => {
        toast.error("You cant change user role!");
      }
    });
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleRoleChangeClick = (userId) => {
    setSelectedUserId(selectedUserId === userId ? null : userId);
  };

  const handleDone = () => {
    UpdateUserRoleFormik.handleSubmit();
    setSelectedUserId(null);
  };

  const handleRemoveUser = (Id) => {
    RemoveUserFormik.handleSubmit()
    RemoveUserFormik.setFieldValue("userId", Id)
  }
  const RemoveUserFormik = useFormik({
    initialValues: {
      adminId: userId,
      userId: '',
      workspaceId: id,
    },
    validationSchema: Yup.object({
      // adminId: Yup.string().required('Admin ID is required'),
      // email: Yup.string().email('Invalid email address').required('Email is required'),
      // role: Yup.string().required('Role is required'),
      // workspaceId: Yup.string().required('Workspace ID is required'),
    }),
    onSubmit: async (values) => {
      RemoveUserMutate(values)
    }
  });
  const { mutate: RemoveUserMutate } =
    useMutation((values) => DeleteUserFromWorkspace(values), {
      onSuccess: (value) => {
        navigator.clipboard.writeText(value.inviteLink)
        queryClient.invalidateQueries("getAllusersOfWorkspce");
      },
      onError: () => {
        toast.error("no Access!");
      },
    });
  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="w-100 " Styles={{ overflowY: "hidden", minHeight: "95vh" }}>
        <div className={Styles.contentWrapper}>
          <AboutWorkspace openInviteModal={toggleInviteModal} workspaceData={workspaceData} />
          <div className="col-12">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div>
                <Col className={Styles.MainContainer} sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div>
                        <h4>Workspace members({CountUsersLoading ?
                          <Spinner
                            thickness="2px"
                            size="m"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="blue.500"
                          /> : MemberCount})</h4>
                        <p>
                          Workspace members can view and join all Workspace
                          visible boards and create new boards in the Workspace.
                        </p>
                      </div>
                      <div className="mt-5 mb-3 tab-footer">
                        <h5>Invite members to join you</h5>
                        <span className="d-flex justify-content-between mb-2">
                          <p className="col-6">
                            Anyone with an invite link can join this free
                            Workspace.
                          </p>
                          <span className="col-5">
                          </span>
                        </span>
                      </div>
                      <>
                        <Dvider color={'rgb(159 173 188 / 24%)'} m={"30px 0"} />
                        <Form.Control
                          name="filter"
                          type="text"
                          placeholder="Filter by name and role ..."
                          className={Styles.searchInput}
                          style={{ width: "250px", backgroundColor: "#22272b", color: "#b6c2cf", border: "1px solid rgb(159 173 188 / 24%)", fontSize: "15px", padding: "5px 15px", borderRadius: "0" }}
                          value={searchTerm}
                          onChange={e => setSearchTerm(e.target.value)}
                        />
                        <Dvider color={'rgb(159 173 188 / 24%)'} m={"16px 0 0 0 "} />
                        {!isLoadingUsers ? (
                          <div className={Styles.Container}>
                            {filteredUsers.length > 0 ? (
                              filteredUsers.map((data, index) => (
                                <div key={index}>
                                  <Dvider color={'rgb(159 173 188 / 24%)'} m={"10px 0"} />
                                  <Flex align={'center'} justifyContent={'space-between'}>
                                    <div className={Styles.MemberContainer}>
                                      <Image
                                        className={Styles.UserImage}
                                        src={`https://placehold.co/480x480/d9e3da/1d2125?text=${data?.email.toUpperCase().slice(0, 1)}`}
                                        rounded
                                      />
                                      <span className="ms-3">
                                        <h2 className={Styles.UserDetailsEmail}>{data?.email?.replace(/@.+$/, "")}</h2>
                                        <h2 className={Styles.UserDetails}>{data?.role}</h2>
                                      </span>
                                    </div>
                                    <div>
                                      <Flex gap={20} alignItems={'center'}>
                                        {selectedUserId !== data?.id && (
                                          <Button
                                            onClick={() => handleRoleChangeClick(data?.id)}
                                            type="submit"
                                            className={Styles.Button}
                                          >
                                            {AddUserLoading ? (
                                              <Spinner
                                                thickness="3px"
                                                size="md"
                                                speed="0.65s"
                                                emptyColor="gray.200"
                                                color="blue.500"
                                              />
                                            ) : (
                                              "Change Role"
                                            )}
                                          </Button>
                                        )}
                                        {selectedUserId === data?.id && (
                                          <Flex gap={10}>
                                            <ChakraProvider>
                                              <Select
                                                onChange={UpdateUserRoleFormik.handleChange}
                                                onBlur={UpdateUserRoleFormik.handleBlur}
                                                name="role"
                                                color={'#b6c2cf'}
                                                w={100}
                                                p="14px 0"
                                                defaultValue={UpdateUserRoleFormik.values.role}
                                                sx={{
                                                  'select:focus + [data-reach-listbox-popover]': {
                                                    backgroundColor: 'red', // Custom background color for the opened menu
                                                  }
                                                }}
                                              >
                                                <option value="Member">Member</option>
                                                <option value="Admin">Admin</option>
                                              </Select>
                                            </ChakraProvider>
                                            <button
                                              onClick={() => {
                                                UpdateUserRoleFormik.setFieldValue("userId", data?.id);
                                                handleDone(data?.id);
                                              }}
                                              type="submit"
                                              disabled={UpdateUserRoleFormik.isSubmitting || RoleChangeLoading}
                                            >
                                              Done
                                            </button>
                                          </Flex>
                                        )}
                                        <ChakraProvider>
                                          <Popover>
                                            <PopoverTrigger>
                                              <Button
                                                style={{ maxHeight: "" }}

                                                type="submit"
                                                className={Styles.Button}
                                              >
                                                Remove user
                                              </Button>
                                            </PopoverTrigger>
                                            <Portal>
                                              <PopoverContent bgColor={"#384148 !important"} color="#d4d6d8">
                                                <PopoverArrow />
                                                <PopoverHeader>Are u sure u want to remove?</PopoverHeader>
                                                <PopoverCloseButton />
                                                <PopoverBody>
                                                  <Button className={Styles.Button} onClick={() => handleRemoveUser(data?.id)}>remove</Button>
                                                </PopoverBody>
                                              </PopoverContent>
                                            </Portal>
                                          </Popover>
                                        </ChakraProvider>
                                      </Flex>
                                    </div>
                                  </Flex>
                                  <Dvider color={'rgb(159 173 188 / 24%)'} m={"10px 0 13px 0"} />
                                </div>
                              ))

                            ) : (
                              <Container className="text-center">
                                <p>No members found.</p>
                              </Container>
                            )}

                          </div>
                        ) : (
                          <ChakraProvider>
                            <Flex justifyContent={'center'} w={'100%'}>
                              <CircularProgress isIndeterminate color='blue.300' />
                            </Flex>
                          </ChakraProvider>
                        )}
                        <Pagination >
                          <Pagination.Prev onClick={() => setPage(old => Math.max(old - 1, 1))} disabled={page === 1} />
                          <Pagination.Item >{page}</Pagination.Item>
                          <Pagination.Next onClick={() => setPage(old => old + 1)} />
                        </Pagination>
                      </>

                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div>
                        1234
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="mt-5 tab-footer">
                        <p className="fst-italic text-center">
                          There are no join requests.
                        </p>
                        <Form>
                          <Form.Group
                            className="mb-1"
                            controlId="create-workspace-name"
                          >
                            <div className="d-flex align-items-center">
                              <div className="w-100 position-relative me-2">
                                <Form.Control
                                  type="text"
                                  placeholder="e.g. calrissian@cloud.ci"
                                  onFocus={() => {
                                    setInputResult(true);
                                  }}
                                  onBlur={() => setInputResult(false)}
                                />
                                {inputResult
                                  ? searchResult &&
                                  searchResult?.length > 0 && (
                                    <div>
                                      {searchResult.map((result) => (
                                        <Card
                                          key={result.id}
                                          className="custom-card p-3 mt-1 position-absolute w-100"
                                        >
                                          <div className="d-flex align-items-center search-result">
                                            <img
                                              className="rounded-circle me-2"
                                              Styles={{
                                                width: "36px",
                                                height: "36px",
                                              }}
                                              src={result.imageUrl}
                                              alt=""
                                            />
                                            <div>
                                              <p className="m-0 small">
                                                {result.name}
                                              </p>
                                              <p className="m-0 small">
                                                {result.lastLogin
                                                  ? "Has logged in recently"
                                                  : "Hasn't logged in recently"}
                                              </p>
                                            </div>
                                          </div>
                                        </Card>
                                      ))}
                                    </div>
                                  )
                                  : ""}
                              </div>
                            </div>
                          </Form.Group>
                        </Form>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
      <InviteModal SetAddUserLoading={SetAddUserLoading} toggleInviteModal={toggleInviteModal} IsWorkspaceInviteModalOpen={IsWorkspaceInviteModalOpen} id={id} />
      <ChakraProvider>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal Title</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
              <Button variant='ghost'>Secondary Action</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
}
