import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import NavDropdown from "react-bootstrap/NavDropdown";
import Styles from './AllBoards.module.css';
import CustomModal from '../../Components/CustomModal/CustomModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { GetAllWorkspaces } from '../../Service/WorkSpaceService';
import { useSelector } from 'react-redux';
import { CreateBoard, getbyWokrspaceInBoard } from '../../Service/BoardService';
import { Form, Spinner } from 'react-bootstrap';
import jwtDecode from "jwt-decode";
import { Flex, Menu, MenuButton, MenuList, useDisclosure } from '@chakra-ui/react';
import Dvider from '../../Components/Dvider';
import { Container } from '@chakra-ui/react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Button from "react-bootstrap/Button";
import image1 from '../../Images/1.jpg';
import image2 from '../../Images/2.jpg';
import image3 from '../../Images/3.jpg';
import image4 from '../../Images/4.jpg';
import image5 from '../../Images/5.jpg';
import image6 from '../../Images/6.jpg';
import { useNavigate } from 'react-router';
import AboutWorkspace from '../../Components/AboutWorkspace/AboutWorkspace';
import InviteModal from '../../Components/InviteModal/InviteModal';
import { useDispatch } from 'react-redux';
import { setData } from '../../Redux/Slices/WorkspaceAndBorderSlice';
export default function AllBoards({ handleChangeSellectedMenu,workspaceData, toggleInviteModal, IsWorkspaceInviteModalOpen, id }) {
    const [modalShow, setModalShow] = useState(false);
    const [Render, setRender] = useState();
    const { workspaceId } = useSelector((x) => x.workspaceAndBoard)
    const [showAlert, setShowAlert] = useState(false);
    const [showAlert2, setShowAlert2] = useState(false);
    const { userId } = useSelector((x) => x.userCredentials)
    const images = [image1, image2, image3, image4, image5, image6]
    const queryClient = useQueryClient();
    const cleanURL = () => {
        const cleanURL = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, cleanURL);
    };
    useEffect(() => {
        cleanURL();
    }, []);
    const updateParentState = (modalShow) => {
        setModalShow(modalShow);
    };

    const {
        isOpen: isBoardOpen,
        onOpen: onBoardOpen,
        onClose: onBoardClose,
    } = useDisclosure();

    const { data: BoardData, isLoading } = useQuery(
        ["GetBoardsInWorkspace", workspaceId ? workspaceId : undefined, userId ? userId : undefined],
        () => getbyWokrspaceInBoard(userId, workspaceId),
        { enabled: !!workspaceId && !!userId },
        {
            cacheTime: 1000 * 60 * 10, // 10 minutes 
            keepPreviousData: true
        }
    );

    const { data: userWorkspace } = useQuery(
        ["GetAllworkspaces", userId ? userId : undefined],
        () => GetAllWorkspaces(userId),
        { enabled: !!userId }
    );

    useEffect(() => {
        queryClient.invalidateQueries("Boards");
        queryClient.invalidateQueries("GetWorkspaceById");
    }, [Render, workspaceId]);

    const CreateBoardFormik = useFormik({
        initialValues: {
            title: "",
            workspaceId: "",
            appUserId: userId,
            theme: ""
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Required").max(200),
            workspaceId: Yup.string().required("Required"),
            appUserId: Yup.string().required("Required"),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            if (values.title && values.workspaceId) {
                CreateBoardMutation(values);
                setSubmitting(false);
                resetForm();
                onBoardClose();
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                }, 2000);
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries("GetBoardsInWorkspace");
        },
    });
    const handleImageClick = (index) => {
        const themeNumber = index + 1;
        CreateBoardFormik.setFieldValue('theme', themeNumber.toString());
        setSelectedImageIndex(themeNumber - 1)
    };
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const { mutate: CreateBoardMutation } = useMutation(
        (values) => CreateBoard(values),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("Boards");
                queryClient.invalidateQueries("GetBoardsInWorkspace");
                setShowAlert2(true);
            },
            onError: () => {
            },
        }
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const HandleSellect = (id) => {
        dispatch(setData({ BoardId: id }));
        handleChangeSellectedMenu('board');
    };
    return (
        <div style={{ overflowY: 'hidden', minHeight: '93vh', width: "100%", padding: "32px", }}>
            <InviteModal SetAddUserLoading={console.log()} toggleInviteModal={toggleInviteModal} IsWorkspaceInviteModalOpen={IsWorkspaceInviteModalOpen} id={id} />
            {isLoading ?
                <Flex className={Styles.SpinnerContiner} margin={'auto'} w={"100%"} alignItems={'center'}>

                    <Spinner
                        style={{ width: "50px", height: "50px" }}
                        margin={'auto'}
                        thickness="3px"
                        size="xl"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                    />
                </Flex>
                :
                userWorkspace && userWorkspace?.data?.length > 0 ? (
                    workspaceData?.data && (
                        <div style={{ color: '#b6c2cf' }} >
                            <AboutWorkspace openInviteModal={toggleInviteModal} workspaceData={workspaceData} />
                            <Container maxW={'max-content'} className={Styles.contentMain}>
                                <h5 style={{ fontWeight: 600 }} className="m-0 mb-3 ">Boards</h5>
                                <Flex flexWrap={"wrap"} maxW={'max-content'} margin={"auto"} gap={20}>
                                    <Menu isOpen={isBoardOpen} onOpen={onBoardOpen} onClose={onBoardClose}>
                                        <MenuButton>
                                            <Card style={{
                                                height: "96px",
                                                width: '300px'
                                            }} className={Styles.BoardCardCreate}>
                                                <Card.Title className={Styles.BoardTitleCreate}>Create new board</Card.Title>
                                            </Card>
                                        </MenuButton>
                                        <MenuList zIndex={2} border={"1px solid #dfe1e61c"} borderRadius={"4px"} minW={"300px"} backgroundColor={"#282e33"}>
                                            <div className={Styles.CreateBoardContainer}>
                                                <div className={Styles.CreateboardHeader}>
                                                    <span onClick={onBoardClose} style={{ fontSize: '14px', cursor: "pointer" }} className="material-symbols-outlined">arrow_back_ios</span>
                                                    <p>Create board</p>
                                                    <span onClick={() => { onBoardClose(); }} style={{ fontSize: '18px', cursor: "pointer" }} className="material-symbols-outlined">close</span>
                                                </div>
                                                <div className={Styles.CreateBoardBody}>
                                                    <div>
                                                        <label className={Styles.BoardTitle} htmlFor="BoardTitle">Background</label>
                                                        <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", cursor: "pointer" }}>
                                                            {images.map((value, index) => (
                                                                <img
                                                                    onClick={() => handleImageClick(index)}
                                                                    style={{
                                                                        borderRadius: "4px",
                                                                        width: "41px",
                                                                        height: "31px",
                                                                        border: selectedImageIndex === index ? '2px solid #579dff' : '2px solid transparent' // Conditional border color
                                                                    }}
                                                                    src={value}
                                                                    alt={`image-${index}`}
                                                                    key={index}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <label className={Styles.BoardTitle} htmlFor="title">Board title*</label>
                                                        <input
                                                            className={Styles.BoardInput}
                                                            style={{ border: CreateBoardFormik.touched.title && CreateBoardFormik.errors.title ? '1px solid red ' : 'none' }}
                                                            placeholder="Enter board title"
                                                            name="title"
                                                            onChange={CreateBoardFormik.handleChange}
                                                            onBlur={CreateBoardFormik.handleBlur}
                                                            value={CreateBoardFormik.values.title}
                                                        />
                                                    </div>
                                                    <label className={Styles.BoardTitle} htmlFor="workspaceId">Select workspace</label>
                                                    <Form.Select
                                                        onChange={CreateBoardFormik.handleChange}
                                                        onBlur={CreateBoardFormik.handleBlur}
                                                        name="workspaceId"
                                                        value={CreateBoardFormik.values.workspaceId}
                                                        style={{ border: CreateBoardFormik.touched.workspaceId && CreateBoardFormik.errors.workspaceId ? '1px solid red' : 'none', color: '#b6c2cf' }}
                                                        className={Styles.SelectInput}
                                                    >
                                                        <option value="">Select a Workspace</option>
                                                        {userWorkspace?.data?.map((workspace, index) => (
                                                            <option key={index} value={workspace.id}>
                                                                {workspace.title}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    <Button
                                                        type="submit"
                                                        style={{
                                                            marginTop: "10px",
                                                            backgroundColor: (CreateBoardFormik.isSubmitting || !CreateBoardFormik.isValid) ? "#2D343A" : "#579dff", // Disabled color is dark gray, normal color is light blue
                                                            color: (CreateBoardFormik.isSubmitting || !CreateBoardFormik.isValid) ? "#ffffff" : "#ffffff", // Text color white for both states for visibility
                                                            borderColor: (CreateBoardFormik.isSubmitting || !CreateBoardFormik.isValid) ? "#2D343A" : "#579dff", // Border color matches the background
                                                            cursor: (CreateBoardFormik.isSubmitting || !CreateBoardFormik.isValid) ? "not-allowed" : "pointer" // Cursor indicates if the button is clickable
                                                        }}
                                                        disabled={CreateBoardFormik.isSubmitting || !CreateBoardFormik.isValid}
                                                        onClick={CreateBoardFormik.handleSubmit}
                                                        className={Styles.CreateBoard}
                                                    >
                                                        Done
                                                    </Button>
                                                </div>
                                            </div>
                                        </MenuList>
                                    </Menu>
                                    {isLoading ? <Spinner
                                        thickness="3px"
                                        size="md"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                    /> :
                                        Array.isArray(BoardData?.data) && BoardData?.data?.length ? (
                                            BoardData?.data?.map((board, index) => {
                                                return (
                                                    <Card
                                                        onClick={() =>{HandleSellect(board.id) } }
                                                        key={index}
                                                        style={{
                                                            backgroundImage: `url(/Images/${board.theme}.jpg)`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            backgroundRepeat: 'no-repeat',
                                                            height: "96px",
                                                            width: '300px'
                                                        }}
                                                        className={Styles.BoardCard}>
                                                        <Card.Title className={Styles.BoardCardTitle}>{board.title}</Card.Title>
                                                    </Card>
                                                );
                                            })
                                        ) : (
                                            <NavDropdown.Item>No boards available</NavDropdown.Item>
                                        )
                                    }
                                </Flex>
                            </Container>
                        </div>
                    )
                ) : (
                    <></>
                )

            }
            <CustomModal
                type={'delete'}
                object={'workspace'}
                message={'Are you sure you want to delete this workspace?'}
                title={'Delete Workspace'}
                show={modalShow}
                updateParentState={updateParentState}
            />
        </div >
    )
}
