import React from 'react'
import { Image } from 'react-bootstrap'
import Styles from './WorkSpace.module.css'
export default function WorkSpace({ Workspace, Click }) {
    const getImageUrl = (base64Data) => {
        if (base64Data) {
            try {
                return `data:image/jpeg;base64,${base64Data}`;
            } catch (error) {
                console.error('Error creating data URL:', error);
            }
        }
        return null;
    };
    const imageUrl = getImageUrl(Workspace?.imageBinaryData);
    return (
        <div onClick={Click} className={Styles.main}>
            <div className={Styles.WorkSpaceContainer}>
                <Image
                    className={Styles.WorkspaceImage}
                    src={imageUrl || `https://placehold.co/512x512/d9e3da/1d2125?text=${Workspace?.title?.toUpperCase().slice(
                        0,
                        1
                    )}`}
                />
                <p className={Styles.WorkspaceText}>{Workspace?.title}</p>
            </div >
        </div>
    )
}
