import React from 'react'
import CommentSend from '../ConmmentSend/CommentSend'
import Styles from './Activity.module.css'
import { Flex } from '@chakra-ui/react'
import { GetComments } from '../../Service/CommnetsService';
import { useQuery } from 'react-query';
import Comment from '../Comment/Comment';
import { GetCardActivity } from '../../Service/CardService';
import ActivityMessageComponent from '../ActivityMessageCompoent/ActivityMessageComponent';
import { Spinner } from 'react-bootstrap';
export default function Activity({ email, cardId, userId, workspaceId }) {
    //get Comments 
    const { data: Comments, isLoading: loading } = useQuery(
        ['Comments', cardId], // Unique key for the query
        () => GetComments(cardId), // Fetch attachments using the GetComments function
        {
            enabled: !!cardId,
            staleTime: Infinity,
            cacheTime: 1000 * 60 * 30 * 1,
        }
    );

    const { data: ActivityData, isLoading: loadingActivity } = useQuery(
        ['Activity', cardId], // Unique key for the query
        () => GetCardActivity(cardId), // Fetch attachments using the GetComments function
        {
            enabled: !!cardId,
            staleTime: Infinity,
            cacheTime: 1000 * 60 * 30 * 1,
        }
    );

    return (
        <Flex flexDir={'column'} gap={3} alignItems={'flex-start'}>
            <Flex gap={2} alignItems={'center'}>
                <span style={{ color: '#9fadbc', fontSize: "22px" }} className="material-symbols-outlined">
                    list
                </span>
                <label className={Styles.title}>Activity</label>
            </Flex>
            <CommentSend workspaceId={workspaceId} userId={userId} cardId={cardId} email={email} />
            {Comments?.data?.map((comment, index) => {
                return (
                    <Comment key={index} comment={comment} /> // Pass comment data as a prop 
                )
            })}
            {loadingActivity ? (
                <Spinner size="xl" /> // Show spinner while loading
            ) : (
                ActivityData?.data?.map((message, index) => {
                    return (
                        <ActivityMessageComponent key={index} message={message} />
                    )
                })
            )
            }
        </Flex>
    )
}
