import React from 'react';
import Styles from './AboutWorkspace.module.css';
import Image from 'react-bootstrap/Image';
import { ChakraProvider, Flex, useDisclosure } from '@chakra-ui/react';
import Dvider from '../Dvider';
import { useMutation, useQueryClient } from 'react-query';
import { useFormik } from 'formik';
import { UpdateWorkSpace } from '../../Service/WorkSpaceService';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    FormControl,
    FormLabel
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

export default function AboutWorkspace({ workspaceData, openInviteModal }) {
    const queryClient = useQueryClient();
    const initialRef = React.useRef(null);
    const finalRef = React.useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { userId } = useSelector((x) => x.userCredentials);
    const { workspaceId } = useSelector((state) => state.workspaceAndBoard);

    const UpdateWorksapceFomik = useFormik({
        initialValues: {
            title: "",
            description: "",
            workspaceId: workspaceId,
            appUserId: userId
        },
        onSubmit: async (values) => {
            values.workspaceId = workspaceId;
            await Update(values);
            onClose();
        },
    });

    const { mutate: Update } = useMutation(
        (data) => UpdateWorkSpace(data),
        {
            onSuccess: async () => {
                await refetchQueries();
            },
            onError: (err) => {
                console.error('Error updating workspace:', err);
            },
        }
    );

    const refetchQueries = async () => {
        await queryClient.invalidateQueries("GetWorkspaceById");
        await queryClient.invalidateQueries("GetAllworkspaces");
    };

    const getImageUrl = (base64Data) => {
        if (base64Data) {
            try {
                return `data:image/jpeg;base64,${base64Data}`;
            } catch (error) {
                console.error('Error creating data URL:', error);
            }
        }
        return null;
    };
    const imageUrl = getImageUrl(workspaceData?.data?.imageBinaryData);

    return (
        <div className={Styles.MainContainer}>
            <Flex maxW={800} margin={"auto"} align={'top'} justify={'space-between'}>
                <Flex flexDir={'column'}>
                    <div className='d-flex align-items-center'>
                        <Image
                            style={{ width: "60px", height:"60px", borderRadius: "5px",objectFit:'cover' }}
                            className='workspace-pic'
                            src={imageUrl || `https://placehold.co/512x512/d9e3da/1d2125?text=${workspaceData?.data?.title?.toUpperCase().slice(0, 1)}`}
                            rounded
                        />
                        <Flex align={'center'} gap={10}>
                            <span className='ms-3'>
                                <h5 className='m-0'>{workspaceData?.data?.title}</h5>
                            </span>
                            <span onClick={() => onOpen()} style={{ fontSize: '17px', color: "#fff", cursor: "pointer" }} className="material-symbols-outlined">
                                edit
                            </span>
                        </Flex>
                    </div>
                    <p style={{ padding: '7px 0', margin: '0', fontSize: '13px' }}>{workspaceData?.data?.description}</p>
                </Flex>
                <button onClick={() => openInviteModal()} className={Styles.InviteButton} >
                    <svg xmlns="http://www.w3.org/2000/svg" height="19px" viewBox="0 -960 960 960" width="24px" fill="#1d2125">
                        <path d="M720-400v-120H600v-80h120v-120h80v120h120v80H800v120h-80Zm-360-80q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm80-80h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0-80Zm0 400Z" />
                    </svg>
                    Invite Workspace members
                </button>
            </Flex>
            <Dvider color="rgb(159 173 188 / 24%)" m={"25px 0"} />
            <ChakraProvider>
                <Modal
                    initialFocusRef={initialRef}
                    finalFocusRef={finalRef}
                    isOpen={isOpen}
                    onClose={onClose}
                >
                    <ModalOverlay />
                    <ModalContent className={Styles.Modal}>
                        <ModalHeader>Update your workspace</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel margin={0}>Title</FormLabel>
                                <Input defaultValue={workspaceData?.data?.title} className={Styles.Input} name='title' onChange={UpdateWorksapceFomik.handleChange} ref={initialRef} placeholder='Title' />
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel margin={0}>Description</FormLabel>
                                <Input defaultValue={workspaceData?.data?.description} className={Styles.Input} name='description' onChange={UpdateWorksapceFomik.handleChange} placeholder='Description' />
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Flex gap={4}>
                                <Button className={"Button"} type='submit' onClick={UpdateWorksapceFomik.handleSubmit} colorScheme='blue' mr={3}>
                                    Save
                                </Button>
                                <Button className={"Button"} onClick={onClose}>Cancel</Button>
                            </Flex>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        </div>
    );
}
