import { httpClient } from "../Utils/HttpClient"

// Create a new comment
export const CreateComment = async (data) => {
    try {
        const result = await httpClient.post(`/api/Comments`, data);
        return result;
    } catch (error) {
        return error;
    }
}

// Get comments by card ID
export const GetComments = async (cardId) => {
    try {
        const result = await httpClient.get(`/api/Comments/${cardId}`);
        return result;
    } catch (error) {
        return error;
    }
}

// Edit an existing comment
export const EditComment = async (data) => {
    try {
        const result = await httpClient.put(`/api/Comments`, data);
        return result;
    } catch (error) {
        return error;
    }
}

// Delete a comment
export const DeleteComment = async (AppUserId,CommentId) => {
    try {
        const result = await httpClient.delete(`/api/Comments`,{
            params:{
                AppUserId,
                CommentId
            }
        });
        return result;
    } catch (error) {
        return error;
    }
}
