import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Spinner, Modal, Button, Form } from 'react-bootstrap';
import { ChakraProvider, Select, Flex } from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { AddUserToWorkspace, GenerateLinkToJoinWorkspace } from '../../Service/WorkSpaceService';
import Styles from '../Members/Members.module.css'; // Assuming you have styles
import { toast } from 'react-toastify';
import { SeachUsers } from '../../Service/AuthService';
export default function InviteModal({ toggleInviteModal, IsWorkspaceInviteModalOpen, id,SetAddUserLoading }) {
    const { userId } = useSelector((x) => x.userCredentials);
    const [searchTerm2, setSearchTerm2] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [userRole, setUserRole] = useState("0")
    const queryClient = useQueryClient()
    // add user
    const workspaceAddUserFormik = useFormik({
        initialValues: {
            adminId: userId,
            userId: '',
            email: '',
            role: "0",
            workspaceId: id,
        },
        validationSchema: Yup.object({
            adminId: Yup.string().required('Admin ID is required'), // Assuming adminId should also be validated
            // email: Yup.string().email('Invalid email address').required('Email is required'),
            role: Yup.string().required('Role is required'),
            workspaceId: Yup.string().required('Workspace ID is required'),
        }),
        onSubmit: async (values) => {
            AddUserToWorkspaceMutation(values)
        }
    });
    const { mutate: AddUserToWorkspaceMutation, isLoading: AdduserLoading } =
        useMutation((values) => AddUserToWorkspace(values), {
            onSuccess: () => {
                queryClient.invalidateQueries("getAllusersOfWorkspce");
                queryClient.invalidateQueries("UsersCount");
                SetAddUserLoading(AdduserLoading)
            },
            onError: () => {
                toast.error("no Access!");
            },
        });
    //generate token 
    const CreateUrlFormik = useFormik({
        initialValues: {
            adminId: userId,
            userId: '',
            email: searchTerm2,
            role: userRole,
            workspaceId: id,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
            values.email = searchTerm2
            GenerateCodeMutatiom(values)
        }
    });
    const { mutate: GenerateCodeMutatiom } =
        useMutation((values) => GenerateLinkToJoinWorkspace(values), {
            onSuccess: (value) => {
                navigator.clipboard.writeText(value.inviteLink)
                toast.success("Token generated and copied");
            },
            onError: () => {
                toast.error("no Access!");
            }
        });
    //seach for the Invite person
    const handleSearchChange = async (e) => {
        const { value } = e.target;
        setSearchTerm2(value);
        if (value.length > 2) {
            try {
                const response = await SeachUsers(value);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results:', error);
                setSearchResults([]);
            }
        } else {
            setSearchResults([]);
        }
    };
    const handleRoleChange = (value) => {
        workspaceAddUserFormik.setFieldValue('role', value)
        CreateUrlFormik.setFieldValue('role', value)
    }
    const handleSelectUser = (user) => {
        setSearchTerm2(user.email);
        workspaceAddUserFormik.setFieldValue('email', user.email);
        setSearchResults([]);
    };

    return (
        <Modal
            show={IsWorkspaceInviteModalOpen}
            onHide={() => {
                toggleInviteModal(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="create-share-link-modal"
        >
            <Modal.Body
                className={Styles.InviteLinkGenerateContainer}
                id="contained-modal-title-vcenter"
            >
                <div className={Styles.HeaderGenerateLink} >
                    <h1>
                        invite to workspace
                    </h1>
                    <div style={{ cursor: 'pointer' }}>
                        <span
                            onClick={() => toggleInviteModal(false)}
                            style={{ color: '#e5e9ee' }} className="material-symbols-outlined">
                            close
                        </span>
                    </div>
                </div>
                <ChakraProvider>
                    <Select
                        onChange={(e) => { handleRoleChange(e.target.value); }}
                        name="role"
                        color={'#b6c2cf'}
                        defaultValue="0"
                        w={200}
                        p="14px 0"
                        sx={{
                            'select:focus + [data-reach-listbox-popover]': {
                                backgroundColor: 'red', // Custom background color for the opened menu
                            }
                        }}
                    >
                        <option value="0">Member</option>
                        <option value="1">Admin</option>
                    </Select></ChakraProvider>
                <Flex gap={10} flexDir={'column'} >
                    <Flex gap={10} alignItems={'center'} >
                        <Form.Control
                            name="email"
                            type="text"
                            placeholder="Search member ..."
                            value={searchTerm2?.replace(/@.+$/, "")}
                            autoComplete="off"
                            onChange={handleSearchChange}
                            className={Styles.Inputinvite}
                            style={{ backgroundColor: "#22272b", color: "#b6c2cf", fontSize: "15px", maxWidth: '100%' }}
                        />
                        <Button onClick={workspaceAddUserFormik.handleSubmit} type="submit" className={Styles.Button} style={{ padding: "9px" }}>
                            {AdduserLoading ? (
                                <Spinner
                                    thickness="3px"
                                    size="md"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                />
                            ) : (
                                "Add User"
                            )}
                        </Button>
                    </Flex>
                    {searchResults?.length > 0 && (
                        <div style={{ color: 'white' }} className={Styles.searchResults}>
                            {searchResults?.map((user, index) => (
                                <div key={index} onClick={() => handleSelectUser(user)} className={Styles.searchResultItem}>
                                    {user.email.replace(/@.+$/, "")}
                                </div>
                            ))}
                        </div>
                    )}
                </Flex>
                <Flex pt={20} alignItems={'center'} justifyContent={'space-between'}>
                    <p className={Styles.InviteDesc} style={{ margin: 0 }}>
                        Invite someone to this Workspace with a link
                    </p>
                    <Button
                        onClick={CreateUrlFormik.handleSubmit}
                        className={Styles.Button} style={{ padding: "9px" }}>
                        {AdduserLoading ? (
                            <Spinner
                                t hickness="3px"
                                size="md"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                            />
                        ) : (
                            "Create link and copy"
                        )}
                    </Button>
                </Flex>
            </Modal.Body>
        </Modal>
    );
}