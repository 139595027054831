import React, { useEffect, useState } from 'react'
import Styles from './WelcomePage.module.scss'
import WelcomePageSideBarMenu from '../../Components/HomePageSideBarMenu/WelcomePageSideBarMenu'
import Allboards from './AllBoards'
import { useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { GetWorkSpaceById } from '../../Service/WorkSpaceService';
import Members from '../../Components/Members/Members'
import InviteModal from '../../Components/InviteModal/InviteModal';
import WorkspaceSettings from '../../Components/WorkspaceSettings/WorkspaceSettings';
import BoardsPage from '../BoardPage/BoardsPage';
export default function WelcomePage() {
    const { workspaceId,BoardId } = useSelector((x) => x.workspaceAndBoard)
    const [Sellected, SetSellected] = useState('boards')
    const [IsWorkspaceInviteModalOpen, SetIsWorkspaceInviteModalOpen] = useState(false);
    const handleChangeSellectedMenu = (value) => {
        SetSellected(value)
    }
    const { data, isSuccess } = useQuery(
        ["GetWorkspaceById", workspaceId],
        () => GetWorkSpaceById(workspaceId),
        {
            staleTime: 1000 * 60 * 5, // 5 minutes
            cacheTime: 1000 * 60 * 10, // 10 minutes 
            keepPreviousData: true
        }
    );
    const toggleInviteModal = (value) => {
        SetIsWorkspaceInviteModalOpen((prevState) => value !== undefined ? value : !prevState);
    };
  //boards 
  const [image, setImage] = useState()
    return (
        <>
            {workspaceId ?
                <>
                    <div className={Styles.MainContainer}>
                        <WelcomePageSideBarMenu setImage={setImage} selectedMenu={Sellected} handleChangeSellectedMenu={handleChangeSellectedMenu} workspaceData={data?.data} />
                        {Sellected === 'boards' && <Allboards handleChangeSellectedMenu={handleChangeSellectedMenu} toggleInviteModal={toggleInviteModal} workspaceData={data} id={data?.data?.id} IsWorkspaceInviteModalOpen={IsWorkspaceInviteModalOpen} />}
                        {Sellected === 'members' && <Members IsWorkspaceInviteModalOpen={IsWorkspaceInviteModalOpen} toggleInviteModal={toggleInviteModal} workspaceData={data} id={data?.data?.id} isSuccess={isSuccess} />}
                        {Sellected === 'settings' && <WorkspaceSettings IsWorkspaceInviteModalOpen={IsWorkspaceInviteModalOpen} toggleInviteModal={toggleInviteModal} id={data?.data?.id} workspaceData={data} isSuccess={isSuccess} />}
                        {Sellected === 'board' &&  <BoardsPage image={image} />}
                    </div>
                    {/* invite modal */}
                    <InviteModal />
                </> 
                :
                <p className={Styles.Text}>
                    Choose an option to either create a new <b>workspace</b> or <b>select</b>  an existing one.
                </p>
            }
        </>
    )
}
