import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Styles from './CostomModal.module.css';
import { Flex } from '@chakra-ui/react';

export default function CustomModal({ show, title, object, updateParentState }) {
  const [showModal, setModalShow] = useState(show);

  const handleClick = () => {
    updateParentState(!show, false);
  };
  const handleClick2 = () => {
    updateParentState(!show, true);
  };

  useEffect(() => {
    setModalShow(show);
  }, [show]);

  return (
    <>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClick}
        className={Styles.Main} // Apply your custom class
      >
        <Modal.Body className={Styles.Modal} id="contained-modal-title-vcenter">
          <Row className='p-0 d-flex flex-nowrap'>
            <div className='py-2 px-3'>
              <Modal.Title id="contained-modal-title-vcenter">
                {title}
              </Modal.Title>
              <h6 className='pt-2'>Do you want to this {object}?</h6>
              <Flex justifyContent={'flex-end'} align={'center'} gap={15}>
                <Button className={Styles.ButtonDelete} onClick={handleClick2}>Delete</Button>
                <Button className={Styles.Button} onClick={handleClick}>Cancel</Button>
              </Flex>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
