import React from 'react';
import { useState } from 'react';
import { Flex, Avatar, Button } from '@chakra-ui/react'; // Import Chakra UI components
import Styles from '../../Pages/BoardPage/CardList/Column.module.css';
import { useMutation, useQueryClient } from 'react-query';
import { CreateComment } from '../../Service/CommnetsService';

export default function CommentSend({ email, cardId, userId, workspaceId }) {
    const [comment, setComment] = useState('');
    const queryClient = useQueryClient();

    const handleSend = async () => {
        if (comment.trim() === '') {
            return; // Prevent sending empty comments
        }
        try {
            await SendCommentMutation({
                cardId,
                message: comment,
                appUserId: userId,
                workspaceId,
            });
            setComment(''); // Clear the input field after sending
        } catch (error) {
            console.error('Error sending comment:', error);
        }
    };
    const handleCancel = () => {
        setComment(''); // Clear the input field
    };
    const { mutate: SendCommentMutation } = useMutation(
        async (values) => CreateComment(values),
        {
            onSuccess: async () => {
                 queryClient.invalidateQueries(['Comments', cardId]);
            },
            onError: (err) => {
                console.error('Error creating comment:', err);
            },
        }
    );

    return (
        <Flex alignItems={'flex-start'} w={'100%'} gap={3}>
            <Avatar mt={"2.5px"} border={'1px solid #74879c6c'} boxSize='33px' size={'s'} fontWeight={'600'} name={email} cursor="pointer" />
            <Flex gap={2} flexDir={'column'} w={'100%'}>
                <input
                    className={Styles.CommentSection}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write your comment here..."
                />
                {comment && (
                    <Flex gap={2}>
                        <Button
                            className={"Button"}
                            style={{
                                width: "max-content",
                                backgroundColor: "transparent",
                                borderColor: '#67a6ff',
                                color: "#67a6ff",
                                padding: "3px 11px",
                                height: "max-content"
                            }}
                            type="button" // Use "button" instead of "submit" to avoid form submission
                            onClick={handleSend}
                        >
                            Save
                        </Button>
                        <Button
                            className={"Button"}
                            style={{
                                width: "max-content",
                                backgroundColor: "transparent",
                                color: "#9fadbc",
                                padding: "3px 11px",
                                height: "max-content"
                            }}
                            type="button"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Flex>

    );
}
