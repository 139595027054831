import React, { useState, useEffect } from 'react'
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Styles from './HomePageSideBarMenu.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGroup, faBarsProgress, faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import CustomModal from '../CustomModal/CustomModal';
import { DeleteWorkSpace, GetAllWorkspaces, UpdateWorkSpace } from '../../Service/WorkSpaceService';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../Redux/Slices/WorkspaceAndBorderSlice';
import { AlertIcon, FormLabel, Stack, useDisclosure, Alert, Flex } from '@chakra-ui/react';
import { ChakraProvider } from '@chakra-ui/react';
import { ReactComponent as BoardIcon } from '../../Images/BoardIcon.svg';
import { GetUserById } from '../../Service/UserService';
import { useNavigate } from 'react-router';
import Dvider from '../Dvider';
import SideBarMenu from '../SideBarMenu/SideBarMenu';

export default function WelcomePageSideBarMenu({ workspaceData, handleChangeSellectedMenu, selectedMenu, setImage }) {

  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { token } = useSelector((x) => x.auth);
  const { userId } = useSelector((x) => x.userCredentials)
  const { workspaceId } = useSelector((state) => state.workspaceAndBoard);
  const queryClient = useQueryClient();




  const HandeUpdateClick = (data) => {
    onOpen()
    dispatch(setData({ workspaceId: data }))
  }

  const [ShowCreateError, setShowCreateError] = useState(false);
  const navigate = useNavigate();
  const HandleNavigate = (data) => {
    navigate(data);
  };
  const [Data, SetData] = useState()

  //get user info
  const handleGetRoles = (workspaceId) => {
    queryClient.invalidateQueries(["getUserById", workspaceId]);
  };


  const { data, isLoading, error, isSuccess } = useQuery(
    ["getUserById", userId, workspaceId],
    () => GetUserById(userId, workspaceId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // Only update localStorage if the data has changed
        const currentRole = localStorage.getItem("Role");
        if (data?.data !== currentRole) {
          localStorage.setItem("Role", data?.data);
        }
      },
      // This ensures that the query will only refetch if `userId` or `workspaceId` changes
      enabled: Boolean(userId || workspaceId),
    }
  );
  const UserRole = localStorage.getItem("Role");
  const getImageUrl = (base64Data) => {
    if (base64Data) {
      try {
        return `data:image/jpeg;base64,${base64Data}`;
      } catch (error) {
        console.error('Error creating data URL:', error);
      }
    }
    return null;
  };
  const imageUrl = getImageUrl(workspaceData?.imageBinaryData);

  const { BoardId } = useSelector((x) => x.workspaceAndBoard);

  return (
    <>
      <div style={{ width: "100%", maxWidth: "260px" }}>
        <ChakraProvider>
          <Stack top={0} right={0} position={'absolute'} spacing={3}>

            {ShowCreateError && (
              <Alert status='error' variant='top-accent'>
                <AlertIcon />
                Workspace couldn't be created!
              </Alert>
            )}
          </Stack>
        </ChakraProvider>
        <Col className={Styles.sideBarMenuWrapper}>
          <Col className={Styles.sideBarMenu}>
            <Flex p={"12px 12px"} alignItems={'center'}>
              <Image className={[Styles.sideBarMenuWorkspacePic, 'me-2']} src={imageUrl || `https://placehold.co/512x512/d9e3da/1d2125?text=${workspaceData?.title?.toUpperCase().slice(
                0,
                1
              )}`} />
              <p style={{ margin: "0", fontWeight: 700 }}>
                {workspaceData?.title}
              </p>
            </Flex>
            <Dvider color={'rgb(159 173 188 / 24%)'} m={"0 0 12px 0"} />
            <Flex flexDir={'column'} >
              <Button className={`${Styles.MenuButton} ${selectedMenu === 'boards' ? Styles.Selected : Styles.MenuButton}`} onClick={() => handleChangeSellectedMenu('boards')} >
                <Flex align={'center'} >
                  <BoardIcon className={Styles.MenuButtonIcon} width="20" height="20" role="presentation" focusable="false" />
                  Boards
                </Flex>
              </Button>
              {UserRole === "Admin" ? (
                <>
                  <Button className={`${Styles.MenuButton} ${selectedMenu === 'members' ? Styles.Selected : Styles.MenuButton}`} onClick={() => handleChangeSellectedMenu('members')}><span ><FontAwesomeIcon className={Styles.MenuButtonIcon} icon={faUserGroup} /></span>Members</Button>
                  <Button className={`${Styles.MenuButton} ${selectedMenu === 'settings' ? Styles.Selected : Styles.MenuButton}`} onClick={() => handleChangeSellectedMenu('settings')} >
                    <Flex align={'center'} >
                      <span className="material-symbols-outlined" style={{ fontSize: "18px", width: "37px", marginRight: "5px", textAlign: 'center' }} >settings</span>
                      Workspace settings
                    </Flex>
                  </Button>
                </>
              ) : null}
              {/* boards */}
              <SideBarMenu selectedMenu={selectedMenu} showBoards={handleChangeSellectedMenu} setImage={setImage} id={BoardId} />
            </Flex>
          </Col>
        </Col >
      </div>
    </>
  );
}